type Config = {
  site: 'hub' | 'self-checkout' | 'jernia-no';

  apiBaseUrl: string;
  cdnUrl?: string;
  hasBasicAuth: boolean;
  imageUrlPrefix: string;
  solrApiPrefix: string;
  url: string;
  useNextLink: boolean;
  isHub: boolean;
};

function configure(): Config {
  const jerniaEnv = process.env.NEXT_PUBLIC_JERNIA_ENV;
  const isDev = process.env.NODE_ENV === 'development';

  switch (jerniaEnv) {
    case 'jernia.no':
      return {
        site: 'jernia-no',
        apiBaseUrl: 'https://www.jernia.no/rest/v2/cnSite',
        cdnUrl: 'https://cdn.jernia.no',
        hasBasicAuth: false,
        imageUrlPrefix: '',
        isHub: false,
        solrApiPrefix: '/solr/api/product',
        url: 'https://www.jernia.no',
        useNextLink: false,
      };
    case 'jernia.no-test':
      return {
        site: 'jernia-no',
        apiBaseUrl: 'https://test.jernia.no/rest/v2/cnSite',
        cdnUrl: 'https://testcdn.jernia.no',
        hasBasicAuth: true,
        imageUrlPrefix: '',
        isHub: false,
        solrApiPrefix: '/solr/api/product',
        url: 'https://test.jernia.no',
        useNextLink: false,
      };
    case 'jernia.no-localhost':
      return {
        site: 'jernia-no',
        apiBaseUrl: 'http://jernia.local.no:9001/rest/v2/cnSite',
        hasBasicAuth: false,
        imageUrlPrefix: '',
        isHub: false,
        solrApiPrefix: '/solr/api/product',
        url: 'http://jernia.local.no:9001',
        useNextLink: false,
      };
    case 'jernia.no-local':
      return {
        site: 'jernia-no',
        apiBaseUrl: 'https://test.jernia.no/rest/v2/cnSite',
        hasBasicAuth: false,
        imageUrlPrefix: '',
        isHub: false,
        solrApiPrefix: '/solr/api/product',
        url: 'http://localhost:8787',
        useNextLink: false,
      };

    case 'self-checkout-test':
      return {
        site: 'self-checkout',
        apiBaseUrl: 'https://test.jernia.no/rest/v2/cnSite',
        hasBasicAuth: true,
        imageUrlPrefix: '/api/imageproxy',
        isHub: true,
        solrApiPrefix: '/api/search',
        url: 'https://test.jernia.no',
        useNextLink: true,
      };

    case 'self-checkout-prod':
      return {
        site: 'self-checkout',
        apiBaseUrl: 'https://www.jernia.no/rest/v2/cnSite',
        hasBasicAuth: false,
        imageUrlPrefix: 'https://www.jernia.no',
        isHub: true,
        solrApiPrefix: '/api/search',
        url: 'https://www.jernia.no',
        useNextLink: true,
      };

    case 'test':
    default:
      return {
        site: 'hub',
        apiBaseUrl: 'https://test.jernia.no/rest/v2/cnSite',
        hasBasicAuth: true,
        imageUrlPrefix: '/api/imageproxy',
        isHub: true,
        solrApiPrefix: isDev ? '/api/search' : '/solr/api/product',
        url: 'https://test.jernia.no',
        useNextLink: true,
      };

    case 'production':
      return {
        site: 'hub',
        apiBaseUrl: 'https://www.jernia.no/rest/v2/cnSite',
        hasBasicAuth: false,
        imageUrlPrefix: 'https://www.jernia.no',
        isHub: true,
        solrApiPrefix: isDev ? '/api/search' : '/solr/api/product',
        url: 'https://www.jernia.no',
        useNextLink: true,
      };
  }
}

export const config = configure();
