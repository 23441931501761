import React, { useContext } from 'react';

export type IconSize = 2 | 3 | 4 | 5 | 6 | 8 | 12;

type Props = {
  size: IconSize;
  children: React.ReactNode;
};

const IconSizeContext = React.createContext<IconSize>(4);

export function IconSizeProvider({ size, children }: Props) {
  return (
    <IconSizeContext.Provider value={size}>{children}</IconSizeContext.Provider>
  );
}

const sizeToClassMap: Record<IconSize, string> = {
  2: 'w-2 h-2',
  3: 'w-3 h-3',
  4: 'w-4 h-4',
  5: 'w-5 h-5',
  6: 'w-6 h-6',
  8: 'w-8 h-8',
  12: 'w-12 h-12',
};

export function useIconSizeClassName(
  sizeFromProp: IconSize | undefined
): string {
  const sizeFromContext = useContext(IconSizeContext);

  const size = sizeFromProp ?? sizeFromContext;

  return sizeToClassMap[size];
}
